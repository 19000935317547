import React from "react";
import SignInUpForm from "../../../components/forms/SignInUp/SignInUpForm";
import { userRoles } from "../../../lib/constants";
import Seo from "../../../components/Seo";
import globhePng from "../../../assets/logos/globhe_logo/Globhe.png";

const ClientLogin = () => {
  return (
    <>
      <Seo
        title={"Client Access | Log In to Your Globhe Account"}
        image={globhePng}
        description={`Log in to your Globhe client portal to monitor project 
        progress, schedule drone operations, and share your data. Your aerial 
        insights are just a click away.`} //image={blogsImage}
      />
      <SignInUpForm formType="login" userType={userRoles.client} />
    </>
  );
};

export default ClientLogin;
